import React, {useState, useEffect, useRef} from "react";
import LazyLoad from "react-lazyload";

import { useStore } from "../../../../context/state";

import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";

import { prettyDateUSA } from "commons-ui/core/utils/date";

import { calcPrice } from "../../../../ui/utils";

import "./Item.module.scss";

import BestSeller from "../../../../images/img/BestSellers.png";
import Vegan from "../../../../images/img/VeganLogo.png";
import Vegetarian from "../../../../images/img/VegetarianLogo.png";

    

function Image(props) {
  const { path, gifPath, noImage, imageHeight, isVertical, isCover, onClick, alt } = props;
  const [showGif, setShowGif] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    // Checks if it's a mobile screen based on the width
    return
    const isMobileScreen = window.innerWidth <= 768;

    let timer = null;

    const handleMouseEnter = () => {
      timer = setTimeout(() => {
        if (gifPath) {
          setShowGif(true);
        }
      }, 300); // 1 second delay for desktop
    };

    const handleMouseLeave = () => {
      clearTimeout(timer);
      setShowGif(false);
    };

    const handleVisibilityChange = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          timer = setTimeout(() => {
            if (gifPath) {
              setShowGif(true);
            }
          }, 300); // 2 seconds delay for mobile
        } else {
          clearTimeout(timer);
          setShowGif(false);
        }
      });
    };

    if (isMobileScreen) {
      // Intersection Observer for mobile devices
      const observer = new IntersectionObserver(handleVisibilityChange, {
        threshold: 1,
      });

      if (imageRef.current) {
        observer.observe(imageRef.current);
      }

      return () => {
        if (imageRef.current) {
          observer.unobserve(imageRef.current);
        }
      };
    } else {
      // Hover events for desktop
      const current = imageRef.current;
      if (current) {
        current.addEventListener("mouseenter", handleMouseEnter);
        current.addEventListener("mouseleave", handleMouseLeave);
      }

      return () => {
        if (current) {
          current.removeEventListener("mouseenter", handleMouseEnter);
          current.removeEventListener("mouseleave", handleMouseLeave);
        }
      };
    }
  }, [gifPath]); // Depend on gifPath to re-run the effect when it changes

  const height = imageHeight || (isVertical ? "260px" : "200px");
  let style = {
    objectFit: "cover",
    backgroundSize: isCover ? "cover" : "contain",
    height,
  };
  if (noImage) {
    style = {
      ...style,
      backgroundSize: "100px",
      backgroundRepeat: "no-repeat",
      opacity: "0.7",
    };
  }

  return (
    <div
      ref={imageRef}
      className={
        "u-relative u-inlineBlock u-verticalAlignTop " +
        (isCover || isVertical ? "u-marginBottom4" : "u-marginVertical4")
      }
      onClick={onClick}
    >
      <LazyLoad>
        <img
          className="image-widget-item u-relative u-sizeFullWidth u-cursorPointer"
          style={style}
          tabIndex="0"
          alt={alt}
          title={alt}
          src={showGif && gifPath ? gifPath : path}
        />
      </LazyLoad>
    </div>
  );
}

export const Item = (props) => {
  const {
    item,
    isLoading,
    loading,
    itemId,
    onItemClick,
    imagesHeight,
    isImagesVertical,
    noPrice,
    isDisabled
  } = props;
  const itemIdValue = item[itemId || "ecommerce_store_id"];

  const { dispatch, isMobile, mediaPath, category_widget, ecommerceStoreId } = useStore();

  if (isLoading || loading) {
    return (
      <div className="tmallItem">
        <Skeleton
          className="u-margin10"
          variant="rect"
          height={isImagesVertical ? 300 : isMobile ? 160 : 190}
        />
        <Skeleton className="u-margin10" width={"80%"} />
        <Skeleton className="u-margin10" width={"60%"} />
      </div>
    );
  }

  let image = item.images && item.images.startsWith("/static") ? item.images : (item.images ? mediaPath + "500@" + (item.images || "").split(",")[0] : "");

  const categoryFlatDict = (category_widget || {}).categoryFlatDict || {};

  const { price_fixed, discount_fixed, discount_percent } = calcPrice(item);
  //const distance = "≈533mi"

  return (
    <div className="u-backgroundColorWhite tmallItem product-gallery-item u-sizeFullHeight u-relative">
      <div
        className="u-flexColumn u-justifyContentStart u-sizeFullHeight u-relative"
        link-meta={"/product/" + itemIdValue}
      >

        <Image
          noImage={!image}
          imageHeight={imagesHeight}
          isVertical={isImagesVertical || item.is_image_vertical === "true"}
          isCover={true}
          alt={item.name}
          path={image || "/static/img/no-image.png"}
          gifPath={"https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/7b29b8136500429.61faa5adae85e.gif"}
          onClick={(event) => onItemClick(itemIdValue, event.currentTarget, item, true)}
        />

        <div className=" u-maxWidth30 img-text-wrapper">
          {item.is_vegan === "true" && <img src={Vegan} title="Vegan" className="img-text-wrapper-item" alt="Vegan"/>}
          {item.is_vegetarian === "true" && <img src={Vegetarian} title="Vegetarian" className="img-text-wrapper-item" alt="Vegetarian"/>}
          {item.is_best_seller === "true" && <img src={BestSeller} title="Best Seller" className="img-text-wrapper-item" alt="Best Seller"/>}
        </div>
         
        <div className="u-flexCenter u-justifyContentCenter u-sizeFullHeight">
          <div
            className="u-marginTop10 u-marginBottom5 u-paddingHorizontal10 u-textAlignCenter u-fontSize16 u-textColorDarker"
            onClick={(event) => onItemClick(itemIdValue, event.currentTarget, item, true)}
          >
            {item.name}
          </div>
        </div>

        {item.product_available_from || item.product_available_till ? (
          <div>
            <div
              className={
                "u-fontSize10 u-textAlignCenter u-paddingVertical15 u-textColorRed " +
                (isMobile ? "u-paddingHorizontal10" : "u-paddingHorizontal15")
              }
            >
              Available only on
              {item.product_available_from
                ? " " +
                  prettyDateUSA(new Date(item.product_available_from)) +
                  ""
                : ""}
              -
              {item.product_available_till
                ? " " +
                  prettyDateUSA(new Date(item.product_available_till)) +
                  ""
                : ""}
            </div>
          </div>
        ) : null}

        <div
          className={
            "u-sizeFullWidth u-sizeFullHeight u-relative u-flexCenter u-justifyContentSpaceBetween u-paddingVertical5 u-paddingBottom10 u-paddingTop0 " +
            (isMobile ? "u-paddingHorizontal10" : "u-paddingHorizontal15") + (noPrice ? " u-justifyContentCenter" : "")
          }
        >
          <div>
            <div
              className={
                "u-flexCenter u-fontWeightBold u-fontSize18 u-textColorDarker " + (noPrice ? "u-hide" : "")
              }
            >
              {item.price_prefix ? <span className="u-textColorNormal u-fontWeightNormal u-fontSize11 u-marginRight4">{item.price_prefix}</span> : null} $ {discount_percent ? discount_fixed : price_fixed}
            </div>
            {discount_percent ? (
              <div className="u-flexCenter">
                <div
                  className={"u-flexCenter u-fontSize9 textColorDarker"}
                  style={{ textDecoration: "line-through" }}
                >
                  $ {price_fixed}
                </div>
                <div className="discount_percent">{discount_percent}%</div>
              </div>
            ) : null}
          </div>
          <div className="u-flexCenter">
            <div>
              {isDisabled ? (
                <span className="not-in-stock u-textColorRed">
                  Sold out
                </span>
              ) : (
                <Button
                  className="button-secondary"
                  link-meta="/checkout"
                  disabled={isDisabled}
                  onClick={(event) => {
                      if (item.redirect) {
                        dispatch(item.redirect);
                      } else {
                        onItemClick(itemIdValue, event.currentTarget, item);
                      }
                      //dispatch("cart", { product: item, count: 1, ecommerceStoreId }, "add_product");
                  }}
                >
                  Order{" "}
                  <AddShoppingCart fontSize="small" className="u-marginLeft5" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
