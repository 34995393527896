import React, { useState } from "react";

import { useStore } from "../../../context/state";

import { Item } from "./Item";

import { ItemGallery } from "../../../ui/item-gallery";

import "./Sales.module.scss";

export const Sales = (props) => {
  const { productList, isDisabled, noPrice, type, size } = props;

  const [loading, setLoading] = useState(false);

  const { isMobile } = useStore();

  if (!loading && productList.length === 0) {
    if (type === "new") {
      return (
        <div className="container u-flexCenter u-justifyContentCenter">
          <span className="u-marginVertical60 u-textColorNormal u-fontSize20">
            No Products
          </span>
        </div>
      );
    }
    return null;
  }

  return (
    <div id="sales-section">
      <div className="container">
        <ItemGallery
          {...props}
          loading={loading}
          marginNegative={props.marginNegative || 30}
          isMobile={props.isMobile === undefined ? isMobile : props.isMobile}
          itemComponent={(props) => {
            return (
              <div className="item u-sizeFullHeight u-padding5">
                <Item
                  item={props.item}
                  {...(props.props || {})}
                  noPrice={noPrice}
                  isDisabled={isDisabled}
                  loading={loading}
                />
              </div>
            );
          }}
          loadingItemsCount={size || 10}
          items={productList}
        />
      </div>
    </div>
  );
};
