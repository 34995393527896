import React from "react";
import { Helmet } from "react-helmet";

import { Consumer as MetaConsumer } from "./context";

import { faviconDefault, websiteDomain } from "../../constants";

const Consumer = ({ children }) => {
  return (
    <MetaConsumer>
      {({ title, description, image, type, url, custom, links, favicon }) => {
        return (
          <>
            <Helmet
              title={title}
              meta={[
                { name: "description", content: description },
                { property: "og:url", content: url },
                { property: "og:title", content: title },
                { property: "og:description", content: description },
                { property: "og:type", content: "website" },
                { property: "og:image", content: image || ((websiteDomain || "") + "/static/img/logo.png") },
                { property: "og:locale", content: "en_US" },
                ...custom,
              ]}
              link={[
                { rel: "shortcut icon", href: favicon || faviconDefault },
                ...(links || []),
              ]}
            />
            {children}
          </>
        );
      }}
    </MetaConsumer>
  );
};

export default Consumer;
