import React from "react";
import { useRouter } from "../../../hooks/useRouter";

import { useStore } from "../../../context/state";

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";

import { addFeatureList, calcPrice, renderNameVertical } from "../../utils";

import { addParamToQueryString } from "../../utils";

import "./Item.module.scss";

function Image(props) {
  const { path, noImage, imageHeight, isVertical, isCover, onClick } = props;
  const style = {
    backgroundImage: `url(${path})`,
    backgroundSize: isCover ? "cover" : "contain",
    height: imageHeight || (isVertical ? "260px" : "150px"),
  };
  if (noImage) {
    style.backgroundSize = "100px";
    style.opacity = "0.8";
  }
  return (
    <div
      className={
        "u-relative u-inlineBlock u-verticalAlignTop " +
        (isCover || isVertical ? "u-marginBottom4" : "u-marginVertical4")
      }
      onClick={onClick}
    >
      <div
        className={
          "image-widget-item u-relative u-sizeFullWidth u-cursorPointer "
        }
        style={style}
      ></div>
    </div>
  );
}

export const Item = (props) => {
  const {
    item,
    isLoading,
    loading,
    itemId,
    noWatermark,
    isImagesCover,
    imagesHeight,
    isImagesVertical,
    featuresFunc,
    imageFeaturesFunc,
    itemActionsComponentFunc,
  } = props;

  const { mediaPath, isMobile, dispatch } = useStore();

  const location = useRouter();

  const itemIdValue = item[itemId || "product_id"];
  const onItemClick = props.onItemClick
    ? props.onItemClick
    : (id) => dispatch(addParamToQueryString("show_product", id, location));
  const onSelect = () => dispatch("/product-form/" + itemIdValue);

  if (isLoading || loading) {
    return (
      <div className="tmallItem">
        <Skeleton
          className="u-margin10"
          variant="rect"
          height={isImagesVertical ? 300 : isMobile ? 160 : 190}
        />
        <Skeleton className="u-margin10" width={"80%"} />
        <Skeleton className="u-margin10" width={"60%"} />
      </div>
    );
  }

  const image = item.image
    ? mediaPath +
      "500@"(noWatermark ? "" : "watermark_") +
      (item.image || "").split(",")[0].replace("product", "product_500x500")
    : "";
  const { price, price_fixed, discount_fixed, discount_percent } =
    calcPrice(item);

  return (
    <div className="tmallItem product-gallery-item">
      <div
        className="u-flexColumn u-justifyContentSpaceBetween u-sizeFullHeight u-relative"
        link-meta={"/product/" + itemIdValue}
      >
        {addFeatureList(
          [
            ...(imageFeaturesFunc ? imageFeaturesFunc(item) : []),
            { name: "Бестселлер", is_hidden: !item.is_bestseller },
            { name: "Новинка", is_hidden: !item.is_novelties },
          ],
          { maxOne: true }
        )}
        <Image
          noImage={!image}
          imageHeight={imagesHeight}
          isVertical={isImagesVertical || item.is_image_vertical === "true"}
          isCover={isImagesCover || item.is_image_cover === "true"}
          path={image || "/static/img/no-image.png"}
          onClick={() => onItemClick(itemIdValue)}
        />
        <div>
          <div
            className="u-marginTop8 u-paddingHorizontal10 u-paddingBottom20"
            onClick={() => onItemClick(itemIdValue)}
          >
            {renderNameVertical(item, {
              features: featuresFunc ? featuresFunc(item) : [],
              isManufacturerFull: true,
            })}
          </div>
          <div
            className={
              "u-hide u-sizeFullWidth u-relative u-flexCenter u-justifyContentSpaceBetween u-paddingVertical5 " +
              (isMobile ? "u-paddingHorizontal10" : "u-paddingHorizontal10")
            }
          >
            <div>
              <div
                className={"u-flexCenter u-fontWeightBold u-fontSize14"}
                style={{ color: "rgb(129, 128, 134)" }}
              >
                {discount_percent ? discount_fixed : price_fixed} ₽
              </div>
              {discount_percent ? (
                <div className="u-flexCenter">
                  <div
                    className={"u-flexCenter u-fontSize9"}
                    style={{ color: "#9d9ca5", textDecoration: "line-through" }}
                  >
                    {price_fixed} ₽
                  </div>
                  <div className="discount_percent">{discount_percent}%</div>
                </div>
              ) : null}
            </div>
            <div className="u-flexCenter">
              {itemActionsComponentFunc ? (
                itemActionsComponentFunc({ props, itemIdValue, item })
              ) : (
                <div>
                  {item.is_not_in_stock === "true" ? (
                    <span className="not-in-stock">
                      Скоро<br></br>появится
                    </span>
                  ) : (
                    <IconButton
                      disabled={!price}
                      className="u-padding2 u-marginLeft5"
                      link-meta="/checkout"
                      onClick={() =>
                        dispatch(
                          "cart",
                          { product: item, count: 1 },
                          "add_product"
                        )
                      }
                    >
                      <AddShoppingCart className="u-textColorNormal u-fontSize19 u-opacity80" />
                    </IconButton>
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            className="u-marginBottom8 u-paddingHorizontal10 u-hide"
            tag-meta="show"
          >
            <Button
              size="small"
              color="primary"
              variant="outlined"
              link-meta="/checkout"
              onClick={() => {
                dispatch(
                  "cart",
                  { product: item, count: 1 },
                  "add_product_without_cart"
                );
                dispatch("/checkout");
              }}
            >
              Купить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
